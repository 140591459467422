import React from "react";
import NavBar from "./components/Navbar";
import { useAuth0 } from "./react-auth0-spa";
import "./App.css";
import VideoPage from "./pages/Video";
import Level from "./pages/Level";
import Footer from "./components/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import PrivateRoute from "./components/PrivateRoute";
import { Router, Switch } from "react-router-dom";
import history from "./utils/history";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import PulseLoader from "react-spinners/PulseLoader";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: green,
  },
});

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function App() {
  const { loading, isAuthenticated } = useAuth0();
  if (loading) {
    return (
      <div style={style}>
        <PulseLoader size={50} color={"#123abc"} loading />;
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <CssBaseline></CssBaseline>
        <Router history={history}>
          <ScrollToTop>
            {isAuthenticated && <NavBar />}
            <Switch>
              <PrivateRoute path="/level/:id" component={Level} />
              <PrivateRoute exact path="/" component={VideoPage} />
            </Switch>
          </ScrollToTop>
        </Router>

        {isAuthenticated && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;
