import dvd1 from "../imgs/thumbnails/dvd1.png";
import dvd2 from "../imgs/thumbnails/dvd2.png";
import dvd3 from "../imgs/thumbnails/dvd3.png";
import dvd4 from "../imgs/thumbnails/dvd4.png";
import dvd5 from "../imgs/thumbnails/dvd5c.png";
import dvd6 from "../imgs/thumbnails/dvd6.png";
import dvd7 from "../imgs/thumbnails/dvd7.png";

const cards = [
  {
    number: 1,
    description: "Improve focus, concentration, and memory skills.",
    img: dvd1,
    video: "https://player.vimeo.com/video/405324124",
    steps: [
      {
        title: "3x3 Magic Square",
        link:
          "https://drive.google.com/open?id=1wYrILOUi1mOtPN8ul9AMnwVWCNm7sS4B",
      },
      {
        title: "5x5 Magic Square",
        link:
          "https://drive.google.com/open?id=1BtCribtNpYB-s89ztvXwM5tbHSqIewFI",
      },
      {
        title: "Amazing Calendar Trick",
        link:
          "https://drive.google.com/open?id=1XM876VDpdYyXMBKWSwHwBa_LO387Ks17",
      },
      {
        title: "Take Ten",
        link:
          "https://drive.google.com/open?id=1zBIY6KjrsCUGaLIN0WNnaEIBuEBuIGRN",
      },
    ],
  },
  {
    number: 2,
    description: "Overcome math anxiety through the use of fun strategies.",
    img: dvd2,
    video: "https://player.vimeo.com/video/405324438",
    steps: [],
  },
  {
    number: 3,
    description:
      "Learn shortcuts for solving complex math problems in your head.",
    img: dvd3,
    video: "https://player.vimeo.com/video/405324857",
    steps: [],
  },
  {
    number: 4,
    description:
      "Develop mental multitasking strategies to help solve any math problem in your head.",
    img: dvd4,
    video: "https://player.vimeo.com/video/405325303",
    steps: [],
  },
  {
    number: 5,
    description:
      "Apply Brainetics skills to every subject or area of your life.",
    img: dvd5,
    video: "https://player.vimeo.com/video/405352685",
    steps: [],
  },
  {
    number: 6,
    description: "Bonus: Fun math shortcuts and cool card tricks.",
    img: dvd6,
    video: "https://player.vimeo.com/video/405353178",
    steps: [],
  },
  {
    number: 7,
    description: "Bonus: Master memorization for life.",
    img: dvd7,
    video: "https://player.vimeo.com/video/405353497",
    steps: [],
  },
];

export default cards;
