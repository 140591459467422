import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Card, CardContent, Link, Zoom, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import levelInfo from "../utils/leveldesc";
import logo from "../imgs/logo-small.png";
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 2),
  },
  player: {
    position: "relative",
    padding: theme.spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  iframe: {
    height: "500px",
    padding: theme.spacing(1, 0),
  },
  img: {
    maxWidth: "300px",
  },
  list: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}));

export default function Level() {
  const classes = useStyles();

  let { id } = useParams();

  return (
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Zoom in timeout={500}>
                <img src={logo} className={classes.img} alt="logo"></img>
              </Zoom>
              <Typography
                variant="h2"
                align="center"
                color="textSecondary"
                paragraph
              >
                Level {id}
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                {levelInfo[id - 1].description}
              </Typography>
              <div className={classes.heroButtons}></div>
            </Box>
          </Container>
        </div>
        <Container maxWidth="lg" className={classes.player}>
          <Grid container spacing={4}>
            <Zoom in timeout={500}>
              <Grid item key={1} xs={12} md={9}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      component="h4"
                      color="textSecondary"
                    >
                      Video
                    </Typography>
                    <iframe
                      title="Video"
                      src={levelInfo[id - 1].video}
                      frameBorder="0"
                      // width="640px"
                      // height="483px"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      className={classes.iframe}
                    ></iframe>
                  </CardContent>
                </Card>
              </Grid>
            </Zoom>
            <Zoom in timeout={500} style={{ transitionDelay: "250ms" }}>
              <Grid item key={2} xs={12} md={3}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      gutterBottom
                      variant="h4"
                      color="textSecondary"
                      component="h4"
                    >
                      The Steps
                    </Typography>
                    {levelInfo[id - 1].steps.map((link) => (
                      <Typography variant="h6" component="h5" key={link.title}>
                        <ul className={classes.list}>
                          <li>
                            <Link target="_blank" href={link.link}>
                              {link.title}
                            </Link>
                          </li>
                        </ul>
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Zoom>
          </Grid>

          {/* <Box display="flex" justifyContent="center">
             <iframe
               src={levelInfo[id - 1].video}
               width="640"
               height="483"
               frameBorder="0"
               allow="autoplay; fullscreen"
               allowFullScreen
             ></iframe>
           </Box> */}
        </Container>
      </main>
    </React.Fragment>
  );
}
