import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Box,
  Zoom,
  CardActionArea,
} from "@material-ui/core";
import { Link as RouteLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../imgs/logo-small.png";
import levelInfo from "../utils/leveldesc";
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 2),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    // width: "178%",
  },
  cardContent: {
    flexGrow: 1,
    minHeight: "180px",
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  img: {
    maxWidth: "300px",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
}));

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Zoom in timeout={500}>
                <img src={logo} className={classes.img} alt="logo"></img>
              </Zoom>
              <Typography
                variant="h2"
                align="center"
                color="textSecondary"
                paragraph
              >
                Home
              </Typography>
            </Box>
          </Container>
        </div>

        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {levelInfo.map((card, idx) => (
              <Zoom
                key={card.number}
                in
                timeout={1000}
                style={{ transitionDelay: `${100 * idx}ms` }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardActionArea className={classes.link} disableRipple>
                      <RouteLink
                        to={`/level/${card.number}`}
                        className={classes.link}
                      >
                        <CardMedia
                          className={classes.cardMedia}
                          image={card.img}
                          title="Image title"
                        />
                        <CardContent className={classes.cardContent}>
                          <Typography gutterBottom variant="h5" component="h2">
                            Level {card.number}
                          </Typography>
                          <Typography>{card.description}</Typography>
                        </CardContent>
                      </RouteLink>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Zoom>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}
