import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import logo from "../imgs/logo-small.png";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
  },
  logo: {
    width: "60px",
    transition: "all 0.2s",
    "&:hover": { width: "75px" },
  },
  profile: {
    marginLeft: "auto",
    maxWidth: "50px",
    margin: "5px 8px",
    borderRadius: "200px",
  },
  linkText: {
    textDecoration: "none",
    color: "#fff",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  console.log(user);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <RouterLink to="/">
            <img src={logo} className={classes.logo} alt="Logo"></img>
          </RouterLink>
          <img
            className={classes.profile}
            src={user.picture}
            alt="Profile"
          ></img>
          <Button href="https://byster.typeform.com/to/OmgIol" color="inherit">
            Support
          </Button>
          {isAuthenticated ? (
            <Button color="inherit" onClick={() => logout()}>
              Log out
            </Button>
          ) : (
            <Button color="inherit" onClick={() => loginWithRedirect({})}>
              Log in
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
